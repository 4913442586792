import { Box, Card, CardContent, CardHeader, FormGroup, Grid, LinearProgress, TextareaAutosize } from "@material-ui/core";
import { Session_Reducer_PushTransactionID } from "actions/SessionActions";
import { SettingsActions } from "components/shared/CardActions";
import { ErrorMessages } from "components/shared/ErrorMessages";
import { ApplyIndicator, ISettingsComponentProps, OverriddenIndicator } from "components/shared/SettingPagesVarious";
import { WebLevelLabel } from "components/shared/WebLevelLabel";
import { useGeneralDisplaySettingsClient } from "hooks/useHttpClient";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TransactionIDInfo } from "types/SessionTypes";
import { AdminEntityValidationResult, AllowedLanguageCode, B2CSettings, CabinClass, CreateSettingsRequest, DOBNameField, OtherSettingsAsKeyValue, SettingsOrder, ShowFareCollapse, showPriceOption, SmartCalender, UpdateGeneralDisplaySettingsModel } from "WebApiClient";

const RouteFilterSettings: React.FC<ISettingsComponentProps> = props => {
  const { ReadOnly, WebLevel, Id, LevelName } = props;
  const client = useGeneralDisplaySettingsClient();
  const dispatch = useDispatch();
  const [smartCalender, setSmartCalender] = useState<SmartCalender | any>();
  const [flightDate, setFlightDate] = useState(true);
  const [isSetting, setIsSetting] = useState(true);
  const [allotmentDate, setAllotmentDate] = useState(false);
  const [Settings, setSettings] = useState<UpdateGeneralDisplaySettingsModel | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [All, setAll] = useState(false);
  const [cabinsSelect, setCabinsSelect] = React.useState(CabinClass);
  const [validationState, setValidationState] = useState<
    AdminEntityValidationResult | undefined
  >(undefined);
  const [OtherSettingsKeyValue, setOtherSettingsKeyValue] = useState<OtherSettingsAsKeyValue | any>(undefined);
  const [serverError, setServerError] = useState(false);

    // Initial filter state with 'order' field - - B2B
    const initialFilterB2B = [
      {  label: "Stops", checked: true, order: 1 },
      { label: "Baggage included", checked: true, order: 2 },
      {  label: "Airports", checked: true, order: 3 },
      { label: "Airlines", checked: true, order: 4 },
      { label: "Alliances", checked: true, order: 5 },
      { label: "Departure time", checked: true, order: 6 },
      { label: "Arrival time", checked: true, order: 7 },
      { label: "Cabin class", checked: true, order: 8 },
      { label: "Fare type", checked: true, order: 9 },
      { label: "QueryPCC", checked: true, order: 10 },
      { label: "BookingPCC", checked: true, order: 11 },
      { label: "Source", checked: true, order: 12 },
      { label: "Allotments", checked: true, order: 13 },
      { label: "Included services", checked: true, order: 14 },
    ];

      // Initial filter state with 'order' field - - B2C
      const initialFilterB2C = [
        { label: "Departure time", checked: true, order: 1 },
        { label: "Arrival time", checked: true, order: 2 },
        {  label: "Stops", checked: true, order: 3 },
        { label: "Baggage included", checked: true, order: 4 },
        { label: "Baggage type", checked: true, order: 5 },
        {  label: "Airports", checked: true, order: 6 },
        { label: "Cabin class", checked: true, order: 7 },
        { label: "Allotments", checked: true, order: 8 },
        { label: "Included services", checked: true, order: 9 },
        { label: "Alliances", checked: true, order: 10 },
        { label: "Airlines", checked: true, order: 11 },
      ];
  const [B2cSettings, setB2cSettings] = useState<B2CSettings>({
    HotelTab: "All",
    AirPortSearchType: "All",
    ShowExtraAncillariesTabB2C: "On",
    ShowLanguageDecimalTabB2C: "English",
    ShowDateInput: "Left",
    ShowDefaultFilter: "On",
    CabinClass: CabinClass,
    TermsConditionsLink: "",
    ShowBackgroundModalColor: "On",
    showMarqueeText: "",
    showMarqueeFrom: "",
    showMarqueeTo: "",
    showBrandName: "On",
    showPassengerTitleName: "On",
    showAssistance: "On",
    showUpsell: "On",
    showUpsellB2B:"On",
    Style: "",
    ConfirmationText: "<p>Thank you for booking.#Booking Reference# You will receive a confirmation email soon.</p>",
    priceOption: "",
    AllowedLanguage: AllowedLanguageCode,
    RouteFilter: "",
    showFilterPosition: "Right",
    filtersOrderB2B: initialFilterB2B,
    filtersOrderB2C: initialFilterB2C,
    fareShowB2B:ShowFareCollapse,
    allowedBookingManagerCouponColumns:[],
  });

  function addOtherSettings(isDelete: boolean) {
    if (
      smartCalender?.showSmartCalender !== undefined ||
      smartCalender?.airline !== undefined ||
      OtherSettingsKeyValue?.showSmartCalender !== undefined ||
      Settings?.otherSettingsAsKeyValueText !== undefined ||
      smartCalender?.flightDates !== undefined ||
      smartCalender?.allotementDates !== undefined
    ) {
      // Create a string of smart calendar settings
      const newString = [
        smartCalender?.showSmartCalender,
        JSON.stringify(smartCalender?.airline),
        smartCalender?.flightDates,
        smartCalender?.allotementDates
      ].filter(Boolean).join("\n");
      setOtherSettingsKeyValue((prevSetting: any) => ({
        ...prevSetting,
        showSmartCalender: newString,
      }));
      const menu = JSON.stringify({ textColor: "#ffffff", bgColor: "#242424" });
      // Create a new string for other settings combining all values in OtherSettingsKeyValue
      const modifiedString = [
        OtherSettingsKeyValue?.menuPosition,
        menu,
        OtherSettingsKeyValue?.fareDisplayHeader,
        OtherSettingsKeyValue?.showSmartCalender,
        OtherSettingsKeyValue?.fareDisplayCabin,
        OtherSettingsKeyValue?.refreshTime,
        JSON.stringify(OtherSettingsKeyValue?.PassengerInfo),
        OtherSettingsKeyValue?.HotelTab,
        JSON.stringify(OtherSettingsKeyValue?.FieldContainer),
        OtherSettingsKeyValue?.CabinContainer
      ].filter(Boolean).join("\n");
      const b2cDelete = isDelete ? { ...B2cSettings, RouteFilter: "" } : B2cSettings;
      setSettings((prev: any) => ({
        ...prev,
        otherSettingsAsKeyValueText: modifiedString,
        b2cSettingsText: JSON.stringify(b2cDelete),
      }));
      return b2cDelete
    }
  }

  useEffect(() => {
    setSettings((prevState: any) => {
      return {
        ...prevState,
        b2cSettingsText: JSON.stringify(B2cSettings),
      };
    });
  }, [
    B2cSettings?.RouteFilter
  ]);

  useEffect(() => {
    GetSettings(WebLevel, Id);
  }, [WebLevel, Id]);

  function OnUpdate() {
    addOtherSettings(false);
    if (Settings) {
      const transactionTime = new Date().toString();
      let transactionSuccess = false;
      let transactionId = "";
      let transactionMsg = "MSG NOT SET";
      let serverSideError = false;
      setLoading(true);
      client.update(Settings).then((response) => {
        transactionId = response.transactionId;
        if (response.success) {
          transactionMsg = "successfully updated.";
          transactionSuccess = true;
        } else {
          transactionMsg = "could not be updated.";
        }
        if (response.serverError) {
          serverSideError = true;
        } else {
          setValidationState(response.validationResult);
        }
      }).catch((e) => {
        console.error(e);
        setServerError(true);
      })
        .finally(() => {
          if (serverSideError) {
            transactionMsg = "could not be updated. A serverside error has occured";
            setServerError(true);
          }
          let transaction: TransactionIDInfo = {
            Label: `Update General display settings`,
            Success: transactionSuccess,
            Time: transactionTime,
            TransactionID: transactionId,
            Message: `General display settings ${transactionMsg}`,
          };
          dispatch(Session_Reducer_PushTransactionID(transaction));
          if (transactionSuccess) {
            Reload();
          }
          setLoading(false);
        });
    }
  }

  function OnDelete() {
    try {
      const b2cDeleteSetting = addOtherSettings(true);
      const newSetting: UpdateGeneralDisplaySettingsModel | any = {
        ...Settings,
        b2cSettingsText: JSON.stringify(b2cDeleteSetting)
      };

      if (newSetting) {
        const transactionTime = new Date().toString();
        let transactionSuccess = false;
        let transactionId = "";
        let transactionMsg = "MSG NOT SET";
        let serverSideError = false;

        setLoading(true);

        client.update(newSetting)
          .then((response) => {
            transactionId = response.transactionId || "";

            if (response.success) {
              transactionMsg = "successfully updated.";
              transactionSuccess = true;
            } else {
              transactionMsg = "could not be updated.";
            }

            if (response.serverError) {
              serverSideError = true;
              transactionMsg = "could not be updated. A server-side error has occurred.";
            } else {
              setValidationState(response.validationResult);
            }
          })
          .catch((error) => {
            console.error("Error during update:", error);
            serverSideError = true;
            transactionMsg = "could not be updated due to an error.";
            setServerError(true);
          })
          .finally(() => {
            const transaction = {
              Label: `Update General Display Settings`,
              Success: transactionSuccess,
              Time: transactionTime,
              TransactionID: transactionId,
              Message: `General display settings ${transactionMsg}`,
            };

            dispatch(Session_Reducer_PushTransactionID(transaction));

            if (transactionSuccess) {
              Reload();
            }

            setLoading(false);
          });
      }
    } catch (error) {
      console.error("Unexpected error in OnDelete:", error);
      setServerError(true);
      setLoading(false);
    }
  }

  function Reload() {
    GetSettings(WebLevel, Id);
  }

  function GetSettings(order: SettingsOrder, Id: string | null) {
    setLoading(true);
    client
      .get(order, Id)
      .then((settings: any) => {
        if (settings !== null) {
          setSettings({
            bookingManagerTab: settings.bookingManagerTab,
            dateFormat: settings.dateFormat,
            fareQueryTab: settings.fareQueryTab,
            otherSettingsAsKeyValueText: settings.otherSettingsAsKeyValueText,
            b2cSettingsText: settings.b2cSettingsText,
            id: settings.id,
            locale: settings.locale,
            startPage: settings.startPage,
          });

          if (settings?.b2cSettingsText) {
            const b2cString = JSON.parse(settings.b2cSettingsText);
            if (b2cString !== null && typeof b2cString === "object") {
              const checkCabin = b2cString.hasOwnProperty("CabinClass");
              const checkLanguage = b2cString.hasOwnProperty("AllowedLanguage");
              const checkRouteFilter = b2cString.hasOwnProperty("RouteFilter");
              if (!checkCabin) {
                b2cString.CabinClass = CabinClass;
              }
              if (!checkLanguage) {
                b2cString.AllowedLanguage = AllowedLanguageCode;
              }
              if (!checkRouteFilter) {
                b2cString.RouteFilter = "";
              }
            }
            setB2cSettings(b2cString);
          } else {
            const B2C: B2CSettings | any = {
              HotelTab: "All",
              AirPortSearchType: "All",
              ShowExtraAncillariesTabB2C: "On",
              ShowLanguageDecimalTabB2C: "English",
              ShowDateInput: "Left",
              CabinClass: CabinClass,
              showMarqueeFrom: "2024-05-20T11:54",
              showMarqueeText: "",
              showMarqueeTo: "2024-05-20T11:55",
              Style: "",
              priceOption: showPriceOption.pricePerPerson,
              AllowedLanguage: AllowedLanguageCode,
              RouteFilter: ""
            };
            setB2cSettings(B2C);
          }

          let otherSetting =
            settings?.otherSettingsAsKeyValueText == null ||
              settings?.otherSettingsAsKeyValueText.length == 0
              ? settings?.otherSettingsAsKeyValueText ==
                "menuPosition=L/nshowFareHeader=Off"
                ? 'menuPosition=L/{"textColor":"#ffffff","bgColor":"#242424"}\nshowFareHeader=Off\nshowSmartCalender=None/n[]/nfalse/nfalse\n{"Economy":{"Checked":true,"preSelect":false},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}\n0\n{"PassengerGender":"Unselected","PassengerDOB":"Yes"}\nshowHotelTab=None\n""\nshowCabinContainer=All'
                : 'menuPosition=L/{"textColor":"#ffffff","bgColor":"#242424"}\nshowFareHeader=Off\nshowSmartCalender=None/n[]/nfalse/nfalse\n{"Economy":{"Checked":true,"preSelect":false},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}\n0\n{"PassengerGender":"Unselected","PassengerDOB":"Yes"}\nshowHotelTab=None\n""\nshowCabinContainer=All'
              : settings?.otherSettingsAsKeyValueText;

          // Split settingsArray and set other settings
          let settingsArray = otherSetting.split("\n");
          // Handle menuPosition and color settings
          let menuSide = "L";
          let textColor = "#ffffff";
          let bgColor = "#242424";
          if (settingsArray[0]) {
            const obj = settingsArray[0].split("/");
            menuSide = obj[0];
            if (obj.length > 1) {
              const menuOption = JSON.parse(obj[1]);
              textColor = menuOption.textColor;
              bgColor = menuOption.bgColor;
            }
          }

          // Handle FieldContainer parsing
          let fieldContainer = { Container: "" };
          if (settingsArray[7]) {
            try {
              fieldContainer = JSON.parse(settingsArray[7]);
            } catch (error) {
              console.error("Error parsing JSON for FieldContainer:", error);
            }
          }

          let passengerInfo;
          // Parse settingsArray[5] if it exists and is a valid JSON string
          if (settingsArray[5]) {
            try {
              // Check if the string is valid JSON
              if (typeof settingsArray[5] === 'string' && settingsArray[5].trim().startsWith('{')) {
                const parsedInfo = JSON.parse(settingsArray[5]);
                passengerInfo = {
                  PassengerGender: parsedInfo.PassengerGender || "Unselected",
                  PassengerDOB: parsedInfo.PassengerDOB || "Yes",
                  DOB: parsedInfo.DOB !== undefined ? parsedInfo.DOB : DOBNameField.ForPaxDobOnly,
                };
              } else {
                throw new Error('Invalid JSON string');
              }
            } catch (e) {
              console.error("Failed to parse PassengerInfo:", e);
              passengerInfo = { PassengerGender: "Unselected", PassengerDOB: "Yes", DOB: DOBNameField.ForPaxDobOnly };
            }
          } else {
            passengerInfo = { PassengerGender: "Unselected", PassengerDOB: "Yes", DOB: DOBNameField.ForPaxDobOnly };
          }
          setOtherSettingsKeyValue({
            menuPosition: menuSide,
            fareDisplayHeader: settingsArray[1] || "showFareHeader=Off",
            showSmartCalendar: settingsArray[2] || "showSmartCalender=None/n[]/nfalse/nfalse",
            fareDisplayCabin: settingsArray[3] || '{"Economy":{"Checked":true,"preSelect":true},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}',
            refreshTime: settingsArray[4] || "0",
            PassengerInfo: passengerInfo,
            HotelTab: settingsArray[6] || "showHotelTab=None",
            FieldContainer: fieldContainer,
            CabinContainer: settingsArray[8] || "showCabinContainer=All",
          });

          // Set settings for cabin classes
          let cabin =
            settingsArray[3] ||
            '{"Economy":{"Checked":true,"preSelect":true},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}';
          if (cabin.includes("preSelect")) {
            let newCabinData = JSON.parse(cabin);
            setCabinsSelect(newCabinData);
            if (
              Object.values(newCabinData).every((cabin: any) => cabin.Checked)
            ) {
              setAll(true);
            }
          } else {
            let newCabinData = {
              Economy: { Checked: true, preSelect: true },
              Premium: { Checked: true, preSelect: false },
              Business: { Checked: true, preSelect: false },
              First: { Checked: true, preSelect: false },
            };
            setCabinsSelect(newCabinData);
            if (Object.values(newCabinData).every((cabin) => cabin.Checked)) {
              setAll(true);
            }
          }

          // Set settings for smart calendar
          let smartCal =
            settingsArray[2] == undefined ||
              settingsArray[2] == "undefined/nundefined/nundefined/nundefined"
              ? "showSmartCalender=None/n[]/nfalse/nfalse"
              : settingsArray[2];
          let smartArray = smartCal.split("/n");
          smartArray = smartArray.includes("undefined")
            ? ["showSmartCalender=None", "[]", "false", "false", ""]
            : smartArray;
          let airlines =
            smartArray[1] == undefined ? [] : JSON.parse(smartArray[1]);
          let flightDates =
            smartArray[2] == undefined ? false : JSON.parse(smartArray[2]);
          let allotement =
            smartArray[3] == undefined ? false : JSON.parse(smartArray[3]);
          setSmartCalender({
            ...smartCalender,
            showSmartCalender:
              smartArray[0] == undefined
                ? "showSmartCalender=None"
                : smartArray[0],
            airline: airlines,
            flightDates: flightDates,
            allotementDates: allotement,
          });
          setFlightDate(flightDates);
          setAllotmentDate(allotement);
          setIsSetting(true);
          setIsSetting(true);
        } else {
          console.log("Settings are null or undefined");
          setIsSetting(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching settings:", error);
        setServerError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <Box boxShadow={ReadOnly ? undefined : 3}>
      <Card style={{ opacity: ReadOnly ? 0.5 : 1 }}>
        <CardHeader
          title={
            <span>
              {props.Applies && (
                <span>
                  <ApplyIndicator />
                </span>
              )}
              {props.IsOverwritten && (
                <span>
                  <OverriddenIndicator />
                </span>
              )}
              <span>Route filter settings for </span>
              <WebLevelLabel Level={WebLevel} Name={LevelName} />{" "}
              {/* {props.IsOverwritten ? "Overwritten" : ""} */}
            </span>
          }
        />
        {!loading && (
          <CardContent>
            {Settings && isSetting ? (
              <Grid container spacing={2}>
                {serverError && <ErrorMessages ServerError={true} />}
                {validationState && validationState.errorSummary && (
                  <ErrorMessages Messages={validationState.errorSummary} />
                )}
                <Grid item xs={6}>
                  <FormGroup>
                    <TextareaAutosize about="root" aria-label="minimum height"
                      minRows={5}
                      placeholder="Enter text here" value={B2cSettings.RouteFilter} onChange={(val) => {
                        setB2cSettings({
                          ...B2cSettings,
                          RouteFilter: val.target.value,
                        })
                      }} />
                  </FormGroup>
                </Grid>
              </Grid>
            ) : (
              // <CreateSettingsButton
              //   OnClick={CreateSettings}
              //   Readonly={props.ReadOnly}
              // />
              <label>
                No General settings were found. Please create general settings for this branch to enable Router filter settings.
              </label>
            )}
          </CardContent>
        )}
        {loading && (
          <CardContent>
            <LinearProgress />
          </CardContent>
        )}
        {!ReadOnly && Settings && isSetting && (
          <SettingsActions
            OnDelete={OnDelete}
            OnReset={Reload}
            OnSave={OnUpdate}
          />
        )}
      </Card>
    </Box>
  );
};

export default RouteFilterSettings;
